import styled from '@emotion/styled';
import theme from '@24hr/resursbank-theme/ya-bank';
import Heading from '../heading';

export const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const StyledBox = styled.div`
    background-color: #FFFFFF;
    box-shadow: 0 9px 17px 0 rgba(0, 0, 0, 0.17);
    margin-top: ${theme.spacing.xlarge};
    margin-bottom: ${theme.spacing.base};
    margin-right: ${theme.spacing.base};
    padding: ${theme.spacing.large};
    position: relative;
    width: calc((100% / 3) - ${theme.spacing.base});
    &:last-of-type {
        margin-right: 0;
    }
    ${theme.mediaQueries.mobileMax} {
        display: block;
        height: auto;
        width: 100%;
        margin-right: 0;
    }
`;

export const StyledHeader = styled(Heading)`
    background-color: ${theme.colors.darkBlue};
    border-radius: 50%;
    color: ${theme.colors.white};
    height: 83px;
    line-height: 80px;
    text-align: center;
    width: 83px;
    position: absolute;
    top: -41px;
`;
