import React from 'react';
import PropTypes from 'prop-types';
import theme from '@24hr/resursbank-theme/ya-bank';

import Text from '../text';
import Button from '../button-component';

import { StyledContainer, StyledBox, StyledHeader } from './style';

const buttonStyles = {
    fullWidth: true,
    background: 'orange',
    textAlign: 'center',
    color: 'white',
    variant: 'filled',
    border: {
        shorthand: 'border',
        color: 'white',
        width: 1,
    },
};

const StepsBoxes = ({ stepsBoxes }, { localize }) => {
    if (stepsBoxes.length === 0) {
        return null;
    }
    return (
        <StyledContainer>
            {stepsBoxes.map((box, index) => {
                const current = index + 1;
                const { url, target, title: linkTitle } = box.linkTo || {};
                return (
                    <StyledBox key={`steps-box-${index}`}>
                        <StyledHeader tag="h3">{current}</StyledHeader>
                        <Text
                            size="normal"
                            weight="bold"
                            margin={`${theme.spacing.medium} 0 0 0`}
                            dangerouslySetInnerHTML
                        >
                            {box.text}
                        </Text>
                        {url && (
                            <Button
                                {...buttonStyles}
                                url={url}
                                target={target || null}
                                css={{marginTop: theme.spacing.medium}}
                            >
                                {linkTitle || localize('read-more')}
                            </Button>
                        )}
                    </StyledBox>
                );
            })}
        </StyledContainer>
    );
};

StepsBoxes.parseProps = atts => {
    return {
        stepsBoxes: atts.steps || [],
    };
};

StepsBoxes.defaultProps = {
    stepsBoxes: [],
};

StepsBoxes.propTypes = {
    stepsBoxes: PropTypes.array,
};

StepsBoxes.contextTypes = {
    localize: PropTypes.func,
};

export default StepsBoxes;
